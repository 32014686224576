import React, { Component } from "react";
import LoaderButton from "../components/LoaderButton";
import "./LicLookup.css";
import {API} from "aws-amplify";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";

export default class LicLook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      isDisabled:false,
      isVersion: "",
      isLicInfo: null,
      isLickey: "",
      isCust: "",
      isDbname:"",
      isExpiry:"",
      isV9os:"",
      isSeq:"",
      isV9Soft:"",
      isV9Version:"",
      isV8Prod:""
    };
  }

  validateForm() {
    return  this.state.isLickey.length > 0  ;
  }

  onClear = event => {
    this.setState({
      isLoading: null,
      isDisabled:false,
      isVersion: "",
      isLicInfo: null,
      isLickey: "",
      isCust: "",
      isDbname:"",
      isExpiry:"",
      isV9os:"",
      isSeq:"",
      isV9Soft:"",
      isV9Version:"",
      isV8Prod:""
    });
  }

  lookupLic() {
      const apiRequest = {
        body: {
            licKey:this.state.isLickey,
            prodVer:this.state.isVersion
            }
      }
      console.log('Just checking the body', apiRequest)

      return API.post("dbvlic", "/dbvlic-lookup", apiRequest)

      }

  handleChange = event => {
      event.target.type === "radio" ?
      this.setState({
        [event.target.id]: event.target.value,
        isLicInfo: ""
      })
      : this.setState({
        [event.target.id]: event.target.value
      })
    }

  handleSubmit = async event => {
    event.preventDefault();

    if (!this.props.isAuthenticated) {
      return;
    }
    console.log('I have got this far')
    this.setState({ isLoading: true })
    console.log('I have got further')

    try {
      console.log('Just about to look stuff up')
      const isLicInfo = await this.lookupLic();

      if(this.state.isVersion === '8') {
        this.setState({
          isCust: isLicInfo.customer_number,
          isDbname: isLicInfo.dbname,
          isExpiry: isLicInfo.expiry_date,
          isV8Prod: isLicInfo.product_id,
          isSeq: isLicInfo.sequence })
      } else {
        this.setState({
          isCust: isLicInfo.customer_number,
          isDbname: isLicInfo.dbname,
          isExpiry: isLicInfo.expiry_date,
          isV9os: isLicInfo.os,
          isV9Version: isLicInfo.version,
          isSeq: isLicInfo.sequence,
          isV9Soft: isLicInfo.software_features})
        }

      this.setState({ isLicInfo });
      console.log('Does this work?',  this.state.isLicInfo)
      } catch (e) {
      alert(e)
     }

     this.setState({
       isLoading: false,
       isDisabled: true
     })
  }

  render() {
    return (
      <div className="LicLook">
      {this.props.isAuthenticated}
        <section className="box">
        <p> Please select the version of License Key requiring Lookup (If you are unsure of the Version please choose V9)</p>
        <label className='LicLook'>
          <input
            id="isVersion"
            type="radio"
            name="isVersion"
            value="8"
            disabled={this.state.isLickey.length > 0}
            checked={this.state.isVersion === "8"}
            onChange={this.handleChange}
          /> Version 8
          <span className="checkmark"></span>
        </label>
        <label className='LicLook'>
          <input
            id="isVersion"
            type="radio"
            name="isVersion"
            value="9"
            disabled={this.state.isLickey.length > 0}
            checked={this.state.isVersion === "9"}
            onChange={this.handleChange}
          /> Version 9
          <span className="checkmark"></span>
        </label>
        </section>
        {this.state.isVersion &&
        <form onSubmit={this.handleSubmit} className="LicLook">
          <FormGroup controlId="isLickey">
            <FormControl
              type="text"
              placeholder={this.state.isVersion === "9" ? "Input your v9 Key" : "Input your v8 Key"}
              onChange={this.handleChange}
              value={this.state.isLickey}
              />
            <HelpBlock className="Smaller">Enter your License Key including hyphens e.g. 4jo6z-8aaai-u09b6-ijjy0-lshc3-1m655-icbjg</HelpBlock>
          </FormGroup>
          {!this.state.isLicInfo &&
          <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              disabled={this.state.isDisabled}
              isLoading={this.state.isLoading}
              text={this.state.isLicInfo ? "Done" : "Lookup Key"}
              loadingText="Performing Lookup…"
          />}
          {this.state.isLicInfo &&
          <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={this.state.isDisabled}
              text="Results"
          />}
        </form>}
          {this.state.isLicInfo && this.state.isVersion === '8' &&
          <section className="resultsBox">
            <p>Here are the details for your key <b>{this.state.isLickey}</b> are:</p>
            <h1><b>Customer Number: {this.state.isCust}</b></h1>
            <h1><b>Database Name:   {this.state.isDbname}</b></h1>
            <h1><b>Expiry Date:     {this.state.isExpiry}</b></h1>
            <h1><b>Product Version: {this.state.isV8Prod}</b></h1>
            <h1><b>Sequence No:     {this.state.isSeq}</b></h1>
          </section>}
          {this.state.isLicInfo && this.state.isVersion === '9' &&
          <section className="resultsBox">
            <p>Here are the details for your key <b>{this.state.isLickey}</b> are:</p>
            <h1><b>Customer Number: {this.state.isCust}</b></h1>
            <h1><b>Database Name:   {this.state.isDbname}</b></h1>
            <h1><b>Expiry Date:     {this.state.isExpiry}</b></h1>
            <h1><b>OS Version:      {this.state.isV9os}</b></h1>
            <h1><b>Product Version: {this.state.isV9Version}</b></h1>
            <h1><b>Sequence No:     {this.state.isSeq}</b></h1>
            <h1><b>Software Features:     {this.state.isV9Soft}</b></h1>
          </section>}
          {this.state.isLicInfo &&
          <LoaderButton
              block
              bsStyle="danger"
              bsSize="large"
              onClick={this.onClear}
              text="Clear and Lookup Another"
          />}
      </div>
    );
  }
}
