export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "dbvlic"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://ws6lvwnoq2.execute-api.us-east-1.amazonaws.com/PROD"
  },
  cognito: {
     REGION: "us-east-1",
     USER_POOL_ID: "us-east-1_5c4c59Jdx",
     APP_CLIENT_ID: "7gam9b3rl7h50iamop45ju6muv",
     IDENTITY_POOL_ID: "us-east-1:b4b88218-c66a-4512-b6e3-d3f298de3736"
   }
};
