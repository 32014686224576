import React, { Component } from "react";
import LoaderButton from "../components/LoaderButton";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import "./NewLicFull.css";
import {API,Auth} from "aws-amplify";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const helpers  = require('./Helpers.js');

export default class NewLicFull extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      isVersion: "",
      isV8custNo: "",
      isV8dbName: "",
      isV8expDate: helpers.addDate(new Date()),
      isV8prodId: "8",
      isV8seqNo: "1",
      isV9custNo:"",
      isV9dbName:"",
      isV9expDate: helpers.addDate(new Date()),
      isV9prodVer:"9",
      isV9osVer:"AnyOS",
      isV9seqNo:"1",
      isV9softFeat:"00000000",
      isLickey: "",
      isDbKnown:true,
      isPerpetual:true,
      copied: false,
      isUser: null,
      isRadio:false
    };
  }

  validate8Form() {
    return this.state.isV8custNo.length > 0 && this.state.isV8dbName.length > 0 && this.state.isV8expDate.length > 0 && this.state.isV8seqNo.length > 0;
  }

  validate9Form() {
    return this.state.isV9custNo.length > 0 && this.state.isV9dbName.length > 0 && this.state.isV9expDate.length > 0 && this.state.isV9seqNo.length  > 0  && this.state.isV9softFeat.length > 0  ;
  }

  onCopy = event => {
    this.setState({copied: true});
  }

createLicFull() {
  const apiRequest8 = {
    body: {
        custNo:this.state.isV8custNo,
        dbName:this.state.isV8dbName,
        expDate:this.state.isV8expDate,
        prodId:this.state.isV8prodId,
        seqNo:this.state.isV8seqNo
        }
  }
  const apiRequest9 = {
    body: {
        custNo:this.state.isV9custNo,
        dbName:this.state.isV9dbName,
        expDate:this.state.isV9expDate,
        prodVer:this.state.isV9prodVer,
        osVer:this.state.isV9osVer,
        seqNo:this.state.isV9seqNo,
        softFeat:this.state.isV9softFeat
      }
  }

    if (this.state.isVersion === "v8" ) {
      return API.post('dbvlic', '/dbvlic8', apiRequest8)
    }
    else
      return API.post("dbvlic", "/dbvlic9", apiRequest9)
  }

  saveLicDetails(){
    if (this.state.isVersion === "v8" ) {
      return API.post('dbvlic', '/dbvrec', {
        body: {username:this.state.isUser,
        licVer:this.state.isVersion,
        licType:"Full",
        trialTerm:this.state.isV8expDate,
        CustNo:this.state.isV8custNo,
        licKey:this.state.isLickey}
      })
    }
    else
      return API.post('dbvlic', '/dbvrec', {
        body: {username:this.state.isUser,
          licVer:this.state.isVersion,
          licType:"Full",
          trialTerm:this.state.isV9expDate,
          CustNo:this.state.isV9custNo,
          licKey:this.state.isLickey}
        })
  }

  onClear = event => {
    this.setState({
      isLoading: null,
      isVersion: "",
      isV8custNo: "",
      isV8dbName: "",
      isV8expDate: helpers.addDate(new Date()),
      isV8prodId: "8",
      isV8seqNo: "1",
      isV9custNo:"",
      isV9dbName:"",
      isV9expDate: helpers.addDate(new Date()),
      isV9prodVer:"9",
      isV9osVer:"AnyOS",
      isV9seqNo:"1",
      isV9softFeat:"00000000",
      isLickey: "",
      isDbKnown:true,
      isPerpetual:true,
      copied: false,
      isUser: null,
      isRadio:false
    });
  }

  handleChange = event => {
    event.target.type === "radio" ?
    this.setState({
      [event.target.id]: event.target.value,
      isLickey: ""
    })
    : this.setState({
      [event.target.id]: event.target.value
    })
  }


  handleCheckChange = event => {
     this.setState({
       isPerpetual: !this.state.isPerpetual
     });

    if (this.state.isPerpetual){
      this.setState({
        isV8expDate: helpers.addDatePer(new Date()),
        isV9expDate: helpers.addDatePer(new Date())
      });
    }
    else {
      this.setState({
        isV8expDate: helpers.addDate(new Date()),
        isV9expDate: helpers.addDate(new Date())
      });
    }
  }

  handleNoDbChange = event => {
     this.setState({
       isDbKnown: !this.state.isDbKnown
     });

    if (this.state.isDbKnown){
      this.setState({
        isV8dbName: "nullDB",
        isV9dbName: "nullDB"
      });
    }
    else {
      this.setState({
        isV8dbName: "",
        isV9dbName: ""
      });
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    if (!this.props.isAuthenticated) {
      return;
    }
    this.setState({ isLoading: true})

    try {
      const userDetails = await Auth.currentUserInfo({bypassCache: true})
      console.log('Details are' , userDetails.username)
      this.setState({
        isUser: userDetails.username
    })
    }catch (e) {
    console.log(e)
    alert(e)
    }


    if(this.state.isUser.length > 0){
      try {
        const key = await this.createLicFull();
        this.setState({
            isLickey: key.LicKey
          })
        }catch (e) {
          console.log(e);
          alert(e)
      }}



    if(this.state.isUser.length > 0 && this.state.isLickey.length) {
      try {
        const res = await this.saveLicDetails();
        console.log('What about my data? ', res)
          }catch (e) {
            console.log(e);
            alert(e)
        }
    }

    this.setState({
      isLoading: false,
      copied:    false,
      isRadio:   true})
  }


  render() {
    return (
      <div className="NewLicFull">
      {this.props.isAuthenticated}
        <section className="box">
        <p> Please select the version of Full License Key required </p>
        <label className='NewLicFull'>
          <input
            id="isVersion"
            type="radio"
            value="v8"
            disabled={this.state.isRadio}
            checked={this.state.isVersion === "v8"  }
            onChange={this.handleChange}
          /> Version 8
          <span className="checkmark"></span>
        </label>
        <label className='NewLicFull'>
          <input
            id="isVersion"
            type="radio"
            disabled={this.state.isRadio}
            value="v9"
            checked={this.state.isVersion === "v9"}
            onChange={this.handleChange}
          /> Version 9
          <span className="checkmark"></span>
        </label>
        </section>
        {this.state.isVersion === "v8" &&
        <form onSubmit={this.handleSubmit} className="FullLic8">
          <FormGroup controlId="isV8custNo">
            <FormControl
              placeholder="Customer Number"
              type="text"
              value={this.state.isV8custNo}
              onChange={this.handleChange}
              />
            <HelpBlock>Enter Customer No : 0-16777215</HelpBlock>
          </FormGroup>
          <FormGroup controlId="isDbKnown">
            <FormControl
              type="checkbox"
              checked={!this.state.isDbKnown}
              onChange={this.handleNoDbChange}
              />
            {!this.state.isDbKnown
              ? <HelpBlock>No Database Name will be included in this license key</HelpBlock>
              : <HelpBlock>Tick if the Database Name is not known at this time</HelpBlock>}
          </FormGroup>
          {this.state.isDbKnown &&
          <FormGroup controlId="isV8dbName">
            <FormControl
              type="text"
              placeholder="Database Name"
              onChange={this.handleChange}
              value={this.state.isV8dbName}
              />
            <HelpBlock>Case Sensitive Database Name: Max 8 symbols</HelpBlock>
          </FormGroup>}
          <FormGroup controlId="isV8perpetual">
            <FormControl
              type="checkbox"
              checked={!this.state.isPerpetual}
              onChange={this.handleCheckChange}
              />
            {!this.state.isPerpetual
                ? <HelpBlock>Perpetual license selected, so the date used will be {this.state.isV8expDate}</HelpBlock>
                : <HelpBlock>Tick if the license is of type Perpetual</HelpBlock>}
          </FormGroup>
          {this.state.isPerpetual &&
          <FormGroup controlId="isV8expDate">
            <FormControl
              type="text"
              placeholder="Expiry Date"
              onChange={this.handleChange}
              value={this.state.isV8expDate}
              />
            <HelpBlock>Term License Expiry Date: Default of a year is selected</HelpBlock>
          </FormGroup>}
          <FormGroup controlId="isV8seqNo">
            <FormControl
              type="text"
              placeholder="Sequence No (default: 1)"
              onChange={this.handleChange}
              value={this.state.isV8seqNo}
              />
            <HelpBlock>License Sequence No. Default is Selected</HelpBlock>
          </FormGroup>
          <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              disabled={!this.state.isLickey ? !this.validate8Form() : true}
              isLoading={this.state.isLoading}
              text={!this.state.isLickey ? "Create Key": "Created"}
              loadingText="Creating…"
          />
        </form>}
        {this.state.isVersion === "v9" &&
        <form onSubmit={this.handleSubmit} className="FullLic9">
          <FormGroup controlId="isV9custNo">
            <FormControl
              placeholder="Customer Number"
              type="text"
              value={this.state.isV9custNo}
              onChange={this.handleChange}
              />
            <HelpBlock>Enter Customer No : 0-999999</HelpBlock>
          </FormGroup>
          <FormGroup controlId="isDbKnown">
            <FormControl
              type="checkbox"
              checked={!this.state.isDbKnown}
              onChange={this.handleNoDbChange}
              />
          {!this.state.isDbKnown
            ? <HelpBlock>No Database Name will be included in this license key</HelpBlock>
            : <HelpBlock>Tick if the Database Name is not known at this time</HelpBlock>}
          </FormGroup>
          {this.state.isDbKnown &&
          <FormGroup controlId="isV9dbName">
            <FormControl
              type="text"
              placeholder="Database Name"
              onChange={this.handleChange}
              value={this.state.isV9dbName}
              />
            <HelpBlock>Case Sensitive Database Name: Max 8 symbols</HelpBlock>
          </FormGroup>}
          <FormGroup controlId="isV9perpetual">
            <FormControl
              type="checkbox"
              checked={!this.state.isPerpetual}
              onChange={this.handleCheckChange}
              />
            {!this.state.isPerpetual
              ? <HelpBlock>Perpetual license selected, so the date used will be {this.state.isV9expDate}</HelpBlock>
              : <HelpBlock>Tick if the License is of type Perpetual</HelpBlock>}
          </FormGroup>
          {this.state.isPerpetual &&
          <FormGroup controlId="isV9expDate">
            <FormControl
              type="text"
              placeholder="Desired Expiry Date"
              onChange={this.handleChange}
              value={this.state.isV9expDate}
              />
            <HelpBlock>Term License Expiry Date: Default of a year is selected</HelpBlock>
          </FormGroup>}
          <FormGroup controlId="isV9osVer">
            <FormControl
              onChange={this.handleChange}
              value={this.state.isV9osVer}
              componentClass="select"
              placeholder="AnyOS">
                <option value="AnyOS">Any OS</option>
                <option value="linux">linux</option>
                <option value="windows">windows</option>
                <option value="solaris">solaris</option>
                <option value="aix">aix</option>
              </FormControl>
            <HelpBlock>Operating System: (linux|windows|solaris|aix)</HelpBlock>
          </FormGroup>
          {this.state.isV9osVer === 'linux' &&
          <FormGroup controlId="isV9softFeat">
            <FormControl
              onChange={this.handleChange}
              value={this.state.isV9softFeat}
              componentClass="select"
              placeholder="Default">
              <option value="00000000">Default</option>
              <option value="10000000">Dbvisit Standby Snapshot</option>
            </FormControl>
            <HelpBlock>Linux OS chosen so option for extra Software Features. Default is No Add Ons (Choose: Default|Dbvisit Standby Snapshot Option)</HelpBlock>
          </FormGroup>}
          <FormGroup controlId="isV9seqNo">
            <FormControl
              type="text"
              placeholder="Sequence No (default: 1)"
              onChange={this.handleChange}
              value={this.state.isV9seqNo}
              />
            <HelpBlock>License Sequence No. Default is Selected</HelpBlock>
          </FormGroup>
          <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              disabled={!this.state.isLickey ? !this.validate9Form() : true}
              isLoading={this.state.isLoading}
              text={!this.state.isLickey ? "Create Key": "Created"}
              loadingText="Creating…"
          />
        </form>}
        <br />
          {this.state.isLickey &&
          <section className="resultsBox">
            <p>Here is your {this.state.isVersion} key:</p>
            <h1><b>{this.state.isLickey}</b></h1>
              <CopyToClipboard
                text={this.state.isLickey}
                onCopy={this.onCopy}>
              <LoaderButton
                  block
                  bsStyle="primary"
                  bsSize="large"
                  text={this.state.copied ? "Copied" : "Copy to Clipboard"}
              />
            </CopyToClipboard>
          </section>}
          {this.state.isLickey &&
          <section className="resultsBox">
          <LoaderButton
              block
              bsStyle="danger"
              bsSize="large"
              onClick={this.onClear}
              text="Clear All and Create Another Key"
          />
        </section>}
      </div>
    );
  }
}
