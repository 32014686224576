import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import "./App.css";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      isTeam:''
    };
  }

  async componentDidMount() {
  try {
    await Auth.currentSession();
    this.userHasAuthenticated(true);
    }
  catch(e) {
    if (e !== 'No current user') {
      console.log('This is in the App currentSession Component')
      alert(e);
    }
  }

  this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  userTeam = (team) => {
   this.setState({
     isTeam: team
    });
   console.log('Here is the team', this.state.isTeam)
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      userTeam: this.userTeam
    };

    console.log(childProps)

    return (
      !this.state.isAuthenticating &&
      <div className="App container">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">DbvLicGen</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
            {this.state.isAuthenticated
              ? <Fragment>
              {(this.state.isTeam === "Admin" || this.state.isTeam === "Sales" || this.state.isTeam !== "Finance") &&
                  <LinkContainer to="/newlic">
                   <NavItem>Trial Key</NavItem>
                  </LinkContainer>}
              {(this.state.isTeam === "Admin" || this.state.isTeam === "Finance" || this.state.isTeam !== "Sales") &&
                  <LinkContainer to="/newlicfull">
                    <NavItem>Full Key</NavItem>
                  </LinkContainer>}
              {(this.state.isTeam === "Admin" || this.state.isTeam === "Finance" || this.state.isTeam !== "Sales") &&
                  <LinkContainer to="/liclookup">
                   <NavItem>Lookup Key</NavItem>
                  </LinkContainer>}
                  <NavItem onClick={this.handleLogout}>Logout</NavItem>
                </Fragment>
              : <Fragment>
                  <LinkContainer to="/login">
                    <NavItem>Login</NavItem>
                  </LinkContainer>
                </Fragment>
            } </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
      </div>
      );
    }
}

export default withRouter(App);
