module.exports = {

'addDays': function(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      },

'addDate': function(date) {
        var d = new Date(date);
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var addon = new Date(year + 1, month, day - 1);
        var result = addon.toISOString().slice(0, 10);
        return result;
      },

'addDatePer': function(date) {
        var t = new Date("January 1, 2099 00:00:01")
        var d = new Date(date);
        var year = t.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var addon = new Date(year, month, day - 1);
        var result = addon.toISOString().slice(0, 10);
        return result;
      },

'toUsername': function(email) {
      return email.replace('@', '-at-');
    }
};
