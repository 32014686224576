import React, { Component } from "react";
import LoaderButton from "../components/LoaderButton";
import "./NewLic.css";
import {API,Auth} from "aws-amplify";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default class NewLic extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      isVersion: "",
      isLickey: "",
      copied: false,
      isUser: null,
      isSlack:null,
      isSaved:'N',
      isComment: "",
      isTerm:"",
      isRadio:false
    };
  }



  onCopy = event => {
    this.setState({copied: true});
  }

  onClear = event => {
    this.setState({
      isLoading: null,
      isVersion: "",
      isLickey: "",
      copied: false,
      isUser: null,
      isSlack:null,
      isSaved:'N',
      isComment: "",
      isTerm:"",
      isRadio:false
    });
  }

  createLic() {
    if (this.state.isVersion === "v8" )
    return API.post("dbvlic", "/dbvlic8-trial", {
      body: {licTerm:this.state.isTerm}
    })
    else
    return API.post("dbvlic", "/dbvlic9-trial", {
      body: {licTerm:this.state.isTerm}
    })
  }

  saveLicDetails(){
      return API.post('dbvlic', '/dbvrec', {
        body: {username:this.state.isUser,
        licVer:this.state.isVersion,
        licType:"Trial",
        CustNo:"None Trial Key",
        trialTerm:this.state.isTerm,
        licKey:this.state.isLickey,
        keyNotes:this.state.isComment}
      })
    }

  sendLicSlack(){
      return API.post('dbvlic', '/dbvslack', {
        body: {slackName:this.state.isSlack,
        Ver:this.state.isVersion,
        Type:this.state.isTerm + " Day Trial",
        licKey:this.state.isLickey}
      })
    }


  handleChange = event => {
      event.target.type === "radio" ?
      this.setState({
        [event.target.id]: event.target.value,
        isLickey: ""
      })
      : this.setState({
        [event.target.id]: event.target.value
      })
    }

  handleClick = async event => {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.setState({ isLoading: true })

    try {
      const userDetails = await Auth.currentUserInfo({bypassCache: true})
      console.log('Details are' , userDetails.username)
      console.log('Slack Details are', userDetails.attributes['custom:Slack'])
      const slack = userDetails.attributes['custom:Slack']
      console.log('Here are the slack details', slack)
      this.setState({
        isUser: userDetails.username,
        isSlack: slack
    })
    }catch (e) {
    console.log(e)
    alert(e)
    }

    if(this.state.isUser.length > 0){
    try {
      const key = await this.createLic();
      this.setState({
        isLickey: key.LicKey
      })
    }catch (e) {
      alert(e)
    }}

    if(this.state.isUser.length > 0 && this.state.isLickey && this.state.isSaved === 'N' ) {
      try {
        const res = await this.saveLicDetails();
        console.log('What about my data? ', res)
        this.setState({
          isSaved: 'Y'
        })
          }catch (e) {
            console.log(e);
            alert(e)
        }
    }

    if(this.state.isSlack.length > 0 && this.state.isSaved === 'Y') {
      try {
        const res = await this.sendLicSlack();
        console.log('How about my Slack update? ', res)
          }catch (e) {
            console.log(e);
            alert(e)
        }
    }

    this.setState({
      isLoading: false,
      copied:    false,
      isRadio:true})
  }


  render() {
    return (
      <div className="NewLic">
      {this.props.isAuthenticated}
        <section className="box">
        <p> Please select the version and duration of Trial License Key required</p>
        <label className='NewLic'>
          <input
            id="isVersion"
            type="radio"
            name="isVersion"
            disabled={this.state.isRadio}
            value="v8"
            checked={this.state.isVersion === "v8"}
            onChange={this.handleChange}
          /> Version 8
          <span className="checkmark"></span>
        </label>
        <label className='NewLic'>
          <input
            id="isVersion"
            type="radio"
            name="isVersion"
            disabled={this.state.isRadio}
            value="v9"
            checked={this.state.isVersion === "v9"}
            onChange={this.handleChange}
          /> Version 9
          <span className="checkmark"></span>
        </label>
          <select className='NewLic'
                  onChange={this.handleChange}
                  value={this.state.isTerm}
                  id="isTerm"
                  disabled={this.state.isRadio}
                  >
            <option>Number of Days</option>
            <option value="3">3 Days</option>
            <option value="10">10 Days</option>
            <option value="14">14 Days</option>
            <option value="30">30 Days</option>
          </select>
          <textarea className="comments"
            id="isComment"
            value={this.state.isComment}
            onChange={this.handleChange}
            disabled={this.state.isRadio}
            cols={60}
            rows={2}
            placeholder="Place comments for this trial key here."
          />
         {this.state.isVersion === "v8" &&
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={this.state.isRadio}
            isLoading={this.state.isLoading}
            onClick={this.handleClick}
            text={!this.state.isLickey ? "Create V8 Trial Key" : "Created"}
            loadingText="Creating your Key…"
          />}
          {this.state.isVersion === "v9" &&
           <LoaderButton
             block
             bsStyle="primary"
             bsSize="large"
             disabled={this.state.isRadio}
             isLoading={this.state.isLoading}
             onClick={this.handleClick}
             text={!this.state.isLickey ? "Create V9 Trial Key" : "Created"}
             loadingText="Creating your Key…"
           />}
          </section>
          {this.state.isLickey &&
          <section className="resultsBox">
            <p>Here is your {this.state.isVersion} key:</p>
            <h1><b>{this.state.isLickey}</b></h1>
              <CopyToClipboard
                text={this.state.isLickey}
                onCopy={this.onCopy}>
              <LoaderButton
                  block
                  bsStyle="primary"
                  bsSize="large"
                  text={this.state.copied ? "Copied" : "Copy to Clipboard"}
              />
            </CopyToClipboard>
          </section>}
          {this.state.isLickey &&
          <section className="resultsBox">
          <LoaderButton
              block
              bsStyle="danger"
              bsSize="large"
              onClick={this.onClear}
              text="Clear All and Create Another Key"
          />
        </section>}
      </div>
    );
  }
}
