import React, { Component } from "react";
import { PageHeader } from "react-bootstrap";
import './Home.css';
import { API, Auth } from "aws-amplify";
import BootstrapTable from 'react-bootstrap-table-next';
import * as moment from 'moment'

const helpers  = require('./Helpers.js');

export default class Home extends Component {

  constructor(props) {
  super(props);

  this.state = {
    isLoading: true,
    isUser: null,
    keys: []
  };
}

async componentDidMount() {
  if (!this.props.isAuthenticated) {
    return;
  }

  try {
  const userDetails = await Auth.currentUserInfo()
  console.log('Details are' , userDetails.username)
  this.setState({
    isUser: userDetails.username
  })
  }catch (e) {
    console.log(e)
    alert(e)
  }

    if(this.state.isUser.length > 0) {
      try {
      const keys = await this.keys();
      this.setState({ keys });
      console.log('Does this work?', this.state.keys)
      } catch (e) {
      alert(e);
      }
    }
    if (this.state.isUser.keys > 0) {
    this.setState({ isLoading: false })
    }
    console.log(this.state.isLoading)
    console.log(this.state.keys);
  }

keys() {
  let myInit = {
    queryStringParameters: {
        username:this.state.isUser
        }
  }
  return API.get('dbvlic', '/dbvkeys', myInit);
}


noKeys() {
    return (
    <div className="keys">
    <p>Just Fetching your keys....</p>
    <p>Don't worry if you have none yet, to generate your first License Key for either version 8 or version 9 please click on the link that is displayed above.</p>
    </div>
  )
}


renderLander() {
  return (
    <div className="lander">
      <h1>Welcome to DbvLicGen</h1>
      <p>The Dbvisit Standby License Key Generating app</p>
      <p>Please login using the link above</p>
    </div>
  );
}

renderTable() {

  const trialHeadText = ['Days (Trial) /',<br />, 'Expiry (Full)'];

  const columns = [{
    dataField: 'ID',
    hidden: true
  }, {
    dataField: 'licenseKey',
    text: 'Key'
  }, {
    dataField: 'licenseType',
    text: 'Type',
    headerAlign: 'center',
    align: 'center'
  }, {
    dataField: 'trialTerm',
    headerAlign: 'center',
    align: 'center',
    text: trialHeadText
  }, {
    dataField: 'requestedDate',
    text: 'Requested',
    headerAlign: 'center',
    align: 'center',
    sort: true
  }, {
    dataField: 'licVersion',
    headerAlign: 'center',
    align: 'center',
    text: 'Version'
  }, {
    dataField: 'CustomerNo',
    headerAlign: 'center',
    align: 'center',
    text: 'Customer Number'
  }, {
    dataField: 'keyNotes',
    headerAlign: 'left',
    align: 'left',
    text: 'Trial Key Comments'
  }
  ];


  const defaultSorted = [{
  dataField: 'requestedDate',
  order: 'desc'
  }];


  const rowSyleExpire = (row) => {
    const style = {};
    var now = moment();

    const reqDate = moment(row.requestedDate,'YYYY-MM-DD')
    console.log (reqDate)


    if (row.licenseType === 'Trial') {
      const trialTerm = parseInt(row.trialTerm)
      console.log (trialTerm)
     if (helpers.addDays(reqDate,trialTerm) < now) {
      style.backgroundColor = '#ed7979';
      style.color = 'white';
      //style.fontWeight = 'bold';
      console.log('Key is Expired')
     } else {
      style.backgroundColor = '#F8F8F8';
      console.log('Key is Fine')
     }} else {
       const expDate = moment(row.trialTerm,'YYYY-MM-DD')
       console.log (expDate)
        if (expDate < now) {
          style.backgroundColor = '#ed7979';
          style.color = 'white';
          console.log('Key is Expired')
        } else {
          style.backgroundColor = '#F8F8F8';
          console.log('Key is Fine')
       }
    }
    return style;
  }

  return (
      <div className="expire">
      <PageHeader><p>Your Created Keys to Date</p></PageHeader>
      <h3>(Expired Keys will be highlighted in Red)</h3>

      <BootstrapTable keyField='ID' data={this.state.keys} columns={columns} defaultSorted={ defaultSorted } rowStyle={ rowSyleExpire } hover noDataIndication='Fetching Data from AWS'/>
      </div>
  );
}

renderKeys() {
  return (
    <div className="keys">
    {this.state.isLoading && this.state.keys.length === 0 ? this.noKeys() : this.renderTable()}
    </div>
  )
}

render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderKeys() : this.renderLander()}
      </div>
    );
  }
}
